type Destination = {
  isCountry: boolean
  isCluster: boolean
}

export const getTrackViewPageType = (destination: Destination) => {
  switch (true) {
    case destination.isCountry:
      return 'landing_destination_country'
    case destination.isCluster:
      return 'landing_destination_cluster'
    case Object.keys(destination).length === 0:
      return 'landing_destination_inspiration'
    default:
      return 'landing_destination_destination'
  }
}

export const buildTrackEventProduct = ({
  houseId,
  houseName,
  iconic,
  onlineBookingAvailable,
  price,
}: {
  houseId: string
  houseName: string
  iconic: boolean
  onlineBookingAvailable: boolean
  price?: number
}) => ({
  house_id: houseId,
  house_name: houseName,
  iconic,
  online_booking_available: onlineBookingAvailable,
  price,
})
